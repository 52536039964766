<template>
    <div class="list-group text-start">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end pb-4">
            <router-link :to="{ name: 'TrainerLessonAdd' }" class="btn default-btn">Add a new Lesson</router-link>
        </div>
        <div v-if="chapters.length">
            <h3 class="text-center">All Chapters and Lessons </h3>
            <div class="accordion accordion-flush text-start" id="accordionFlushExample">
                <div class="accordion-item" v-for="content in chapters" :key="content.id">
                    <h2 class="accordion-header" :id="'flush-heading'+content.id">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse'+content.id" aria-expanded="false" :aria-controls="'flush-collapse'+content.id">
                        {{ content.title }}
                    </button>
                    </h2>
                    <div :id="'flush-collapse'+content.id" class="accordion-collapse collapse" :aria-labelledby="'flush-heading'+content.id" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <ol class="list-group list-group-numbered">
                                <li class="list-group-item d-flex justify-content-between align-items-start"  v-for="lesson in content.lessons" :key="lesson.id">
                                    <div class="ms-2 me-auto">
                                        {{ lesson.name }}
                                    </div>
                                    <span class="badge">
                                        <router-link :to="{ name: 'TrainerLesson', params: {id: lesson.id}}" class="btn text-default">
                                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                        </router-link>
                                        <router-link :to="{ name: 'TrainerLessonEdit', params: {id: $route.params.id}, params: {lesson: lesson.id}}" class="btn text-default">
                                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                                        </router-link>
                                        <button class="btn text-default" @click="deleteLesson(lesson.id)">
                                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                        </button>
                                    </span>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center" v-else>
            <h3>No Lesson Has Been Added</h3>
<!--            <router-link :to="{ name: 'TrainerLessonAdd' }" class="btn default-btn">Add A Lesson</router-link>-->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
    props: ['course','course_content'],
    data() {
        return {
            chapters: this.course_content
        }
    },
    methods:{
        deleteLesson(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff1949',
            cancelButtonColor: '#ffc107',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.post('https://apitraining.vipawaworks.com/api/lesson/delete/'+ id)
                .then(response => {
                    response.data
                        this.$emit("refreshCourse")
                }).catch((errors) => {
                    this.errors = errors.response.data.errors
                })
                Swal.fire(
                'Deleted!',
                'Lesson has been Deleted.',
                'success'
                )
            }
            })
        },
    }
}
</script>

<style>

</style>
