<template>
   <div class="instructor-details">
        <div class="instructor-details-info">
            <h2 class="text-center">Publish {{ course.title }}</h2>
            <table class="table">
                <tbody class="text-center">
                    <tr>
                        <td :class="{ 'text-default': course.ispublished == 0 }">
                            Daft
                        </td>
                        <td  :class="{ 'text-default': course.ispublished == 1 }">Published</td>
                        <td>Verified</td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center">
                <div v-if="course.ispublished == 0">
                    <p>
                        This course is in <strong>Draft</strong> state, that means it is not ready to be accessible to the users. 
                        When the course is ready you can publish it anytime. After publish, we will review the course and make it available to the users.
                        Unless otherwise you will be notified what needs to be improved for your course to appear. 
                    </p>
                    <button class="default-btn" @click="publishCourse()">Publish</button>
                </div>
                <div v-else-if="course.ispublished == 1">
                    <p>
                        This course is in <strong>Published</strong> state, it is waiting for administator to review so that it can be available. 
                        For now You cannot edit/modify the course until the review process is over, unless otherwise you unpublish it. Your course will be available to the user
                    </p> 
                    <button class="default-btn" @click="unPublishCourse()">UnPublish</button>
                </div>
               
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['course'],
    methods:{
        publishCourse(){
            let id = this.$route.params.id
            axios.post('https://apitraining.vipawaworks.com/api/course/publish/' + id).then(response =>{
                response.data
                this.$router.push({ name: 'TrainerCourses'})
            }).catch((errors) => {
                this.errors = errors.response.data.errors
            })
        },
        unPublishCourse(){
            let id = this.$route.params.id
            axios.post('https://apitraining.vipawaworks.com/api/course/unpublish/' + id).then(response =>{
                response.data
                this.$router.push({ name: 'TrainerCourses'})
            }).catch((errors) => {
                this.errors = errors.response.data.errors
            })
        },
    }
}
</script>

<style>

</style>