<template>
    <Navbar :user="user"/>
    <section class="instructor-details-area pt-100 pb-70" v-if="!isLoading">
        <div class="container">
            <div class="instructor-details-desc">
                <tabs :options="{ defaultTabHash: 'CourseTitle' }" wrapper-class="row text-start" nav-item-class="nav-item" nav-class="col-md-3 nav flex-column nav-tabs" panels-wrapper-class="col-md-9" nav-item-link-class="nav-link btn" nav-item-link-active-class="active section-title">
                    <tab id="CourseTitle" name="General Information">
                        <div class="card">
                            <div class="card-body">
                                <Title :course="course" />

                            </div>
                        </div>
                    </tab>
                    <tab id="CourseRequirement" name="Course Requirements and Descriptions">
                        <div class="card">
                            <div class="card-body">
                                <Requirements :course="course" />

                            </div>
                        </div>
                    </tab>
                    <tab id="CourseChapters" name="Course Chapters">
                        <div class="card">
                            <div class="card-body">
                                <Chapters :course="course" :course_content="course_content" @refreshCourse="getCourseContent" />

                            </div>
                        </div>
                    </tab>
                    <tab id="CourseLessons" name="Course Lesson">
                        <div class="card">
                            <div class="card-body">
                                <Lessons :course="course" :course_content="course_content"  @refreshCourse="getCourseContent"/>

                            </div>
                        </div>
                    </tab>
                    <tab id="CourseQuiz" name="Course Quizes">
                        <div class="card">
                            <div class="card-body">
                                <Quizes :course="course" :quizes_content="quizes_content"  @refreshCourse="getCourseContent" />

                            </div>
                        </div>
                    </tab>
                    <tab id="CourseTest" name="Course Test">
                        <div class="card">
                            <div class="card-body">
                                <Tests :course="course" :tests="tests"  @refreshCourse="getCourseContent" />

                            </div>
                        </div>
                    </tab>
                    <tab id="CourseSubmit" name="Course Review and Submit">
                        <div class="card">
                            <div class="card-body">
                                <Publish :course="course"/>

                            </div>
                        </div>
                    </tab>
                </tabs>
            </div>
        </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import Title from '@/components/trainer/courses/Title.vue'
import Requirements from '@/components/trainer/courses/Requirements.vue'
import Chapters from '@/components/trainer/courses/Chapters.vue'
import Lessons from '@/components/trainer/courses/Lessons.vue'
import Quizes from '@/components/trainer/courses/Quizes.vue'
import Publish from '@/components/trainer/courses/Publish.vue'
import Tests from '@/components/trainer/courses/Tests.vue'
export default {
 components: { Navbar,Loading,Title,Requirements,Chapters,Lessons,Quizes,Publish,Tests },
  data(){
        return {
            isLoading: true,
            token: localStorage.getItem('user_data'),
            user: {},
            errors: {},
            test: {},
            course: {},
            quizes_content: {}
        }
    },
    methods:{
        getCourse(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/course/profile/' + id).then(response => {
                this.course = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getCourseQuiz(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/course_content/get_contents_with_quizzes/'+ id).then(response => {
                this.quizes_content = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getCourseTest(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/test/get_test_with_questions/'+ id).then(response => {
                this.tests = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getCourseContent(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/course_content/get_contents_with_lessons/' + id).then(response => {
                this.course_content = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },

        deleteCourse(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff1949',
            cancelButtonColor: '#ffc107',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.post('https://apitraining.vipawaworks.com/api/course/delete/'+ id)
                .then(response => {
                    response.data
                     this.courses = this.courses.filter(course => {
                      return course.id != id
                    })
                }).catch((errors) => {
                    this.errors = errors.response.data.errors
                })
                Swal.fire(
                'Deleted!',
                'Specialist has been Deleted.',
                'success'
                )
            }
            })
        },
        getUser(){
            axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
                this.user = response.data
            }).catch(errors => {
                if (errors.response.status === 401) {
                localStorage.removeItem('user_data')
                this.$router.push({ name: 'Login'})
                }
            })
        },
    },
    created(){
        document.title = `Course Lessons - Training`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        this.getUser()
        this.getCourse()
        this.getCourseContent()
        this.getCourseQuiz()
        this.getCourseTest()
    }
}
</script>

<style>
    .instructor-details-area .section-title {
        margin-bottom: 0px;
    }
</style>
