<template>
    <div class="list-group text-start">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end pb-4">
            <router-link :to="{ name: 'TrainerChapterAdd' }" class="btn default-btn">Add Chapter</router-link>
        </div>
        <div v-if="chapters.length">
            <h3 class="text-center">All Chapters</h3>
            <ul class="list-group" v-for="chapter in chapters" :key="chapter.id">
                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="ms-2 me-auto">
                    <div class="fw-bold">{{ chapter.title }}
                        <span v-if="chapter.lessons.length" class="text-default">
                            ({{ chapter.lessons.length }}) <span v-if="chapter.lessons.length === 1"> lesson</span><span v-else>lessons</span>
                        </span>
                        <span v-else class="text-default">
                            No Lesson Added
                        </span>
                    </div>
                    </div>
                    <span class="badge text-default rounded-pill">
                        <!-- <router-link :to="{ name: 'CourseDashboard', params: {id: chapter.id}}" class="btn text-default">
                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                        </router-link> -->
                        <router-link :to="{ name: 'TrainerChapterEdit', params: {id: $route.params.id}, params: {chapterId: chapter.id}}" class="btn text-default">
                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                        </router-link>
                        <button class="btn text-default" @click="deleteChapter(chapter.id)">
                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                        </button>
                    </span>
                </li>
            </ul>
        </div>
        <div class="text-center" v-else>
            <h3>No Lesson Has Been Added</h3>
<!--            <router-link :to="{ name: 'TrainerLessonAdd' }" class="btn default-btn">Add A Lesson</router-link>-->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
    props: ['course','course_content'],
    data() {
        return {
            chapters: this.course_content
        }
    },
    methods:{
        deleteChapter(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff1949',
            cancelButtonColor: '#ffc107',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.post('https://apitraining.vipawaworks.com/api/course_content/delete/'+ id)
                .then(response => {
                    response.data
                        this.chapters = this.chapters.filter(chapter => {
                        this.$emit("refreshCourse")
                        return chapter.id != id
                    })
                }).catch((errors) => {
                    this.errors = errors.response.data.errors
                })
                Swal.fire(
                'Deleted!',
                'Specialist has been Deleted.',
                'success'
                )
            }
            })
        },
    }
}
</script>

<style>

</style>
