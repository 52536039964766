<template>
      <div class="instructor-details">
        <div class="instructor-details-info">
            <div class="row" v-if="course.description"> 
                <div class="d-flex flex-row-reverse">
                    <router-link :to="{ name: 'TrainerDescriptionCourses'}" class="btn text-default end-0">
                        <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                    </router-link>
                </div>
                <div class="text-center">
                    <h3 class="text-center">Coures Descriptions:</h3>
                    <span v-html="course.description"></span>
                    <h3 class="text-center">Coures Requirement:</h3>
                    <span v-html="course.course_requirements"></span>
                    <h3 class="text-center">What You will Learn:</h3>
                    <span v-html="course.what_will_learn"></span>
                </div>
            </div>
            <div class="text-center" v-else>
                <h4>No Course Description Added</h4>
                <router-link :to="{ name: 'TrainerDescriptionCourses'}" class="btn default-btn end-0">
                    Add Descriptions
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
export default {
    props: ['course'],
}
</script>

<style>

</style>