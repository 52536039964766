<template>
    <div class="instructor-details">
        <div class="instructor-details-info">
            <div class="row">
                <div class="d-flex flex-row-reverse">
                    <router-link :to="{ name: 'TrainerEditCourses'}" class="btn text-default end-0">
                        <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                    </router-link>
                </div>
                <div class=" text-center">
                    <div class="alert alert-success" v-if="success">
                        <p class="text-center">{{ success }}</p>
                    </div>
                    <div class="alert alert-danger" v-if="imageErrors">
                        <p class="text-center">{{ imageErrors }}</p>
                    </div>
                    <div class="alert alert-danger" v-else-if="errors[0]">
                        <p class="text-center">{{ errors[0] }}</p>
                    </div>
                    <div class="alert alert-danger" v-else-if="errors">
                        <p class="text-center">{{ errors }}</p>
                    </div>
                    <div class="instructor-details-sidebar">
                        <div v-if="form.photo">
                            <img :src="form.photo" class="avatar float-md-left avatar-medium shadow me-md-4" alt="">
                        </div>
                        <div v-else-if="course.cover_photo">
                            <img v-bind:src="'https://apitraining.vipawaworks.com/' + course.cover_photo" class="avatar float-md-left avatar-medium shadow me-md-4" alt="">
                        </div>
                        <div v-else>
                            <img src="@/assets/img/courses/1.jpg" class="avatar float-md-left avatar-medium shadow me-md-4" alt="image" />
                        </div>
                    </div>
                    <button v-on:click="editable = !editable" class=" btn text-default mt-2 border-0 bg-light collapsed" type="button">
                        <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                    </button>
                    <form @submit.prevent="profileUpload" v-if="editable" id="hide" enctype="multipart/form-data" method="post">
                        <div class="custom-file">
                            <input class="custom-file-input input-sm" @change="imageSelected" type="file"  id="customFile">
                        </div>
                        <button class="btn btn-outline-success" disabled v-if="submitting">
                                <span class="label">{{ value }}</span>
                            </button>
                        <button type="submit" class="btn btn-outline-success mt-2 ms-2" v-else>Upload</button>
                    </form>
                </div>
                <div class="">
                    <h3 class="text-center">Coures Title: {{ course.title }}</h3>
                    <h3 class="text-center">Coures Category: {{ course.category_name }}</h3>
                    <h3 class="text-center">Coures Sub Category: {{ course.sub_category_name }}</h3>
                    <h3 class="text-center">Coures Subject: {{ course.subject_name }}</h3>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['course'],
    data(){
        return {
            editable: false,
            submitting: false,
            success: false,
            errors: false,
            imageErrors: false,
            coursePhoto:null,
            form:{
              photo: null
            },
            sub_category:{},
            subject:{}
        }
    },
    methods:{
        getCategories(){
            axios.get('https://apitraining.vipawaworks.com/api/course/get_all_categories').then(response => {
                this.categories = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getSubCategory(){
            axios.get('https://apitraining.vipawaworks.com/api/course/get_all_sub_categories/' + this.course.sub_course_category_id ).then(response => {
                this.sub_category = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getSubject(){
            axios.get('https://apitraining.vipawaworks.com/api/course/get_all_subjects/' + this.course.subject_id ).then(response => {
                this.subject = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        imageSelected(event){
            let file = event.target.files[0]
            if (file.size > 1548770) {
                this.imageErrors = 'Image Should be less than 1MB'
            }else{
                this.errors = false
                let reader = new FileReader()
                reader.onload = event => {
                    this.form.photo = event.target.result
                }
                reader.readAsDataURL(file)
            }
        },
        profileUpload(){
            this.submitting = true
            this.value = 'Uploading...'
            axios.post('https://apitraining.vipawaworks.com/api/course/upload_cover/' + this.course.id, this.form).then(response => {
                this.$refs.clearForm.reset(),
                this.coursePhoto = response.data
                this.success = 'Cover Photo Changed'
                this.editable = !this.editable
                this.submitting = false
            }).catch((error) => {
                if (!error.response) {
                    this.editable = !this.editable
                    return this.success = 'Profile Picture Changed'
                }
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
        onChange(e) {
            this.file = e.target.files[0];
        },


        // updated function to upload image
        formSubmit(e) {
            e.preventDefault();
            let existingObj = this;

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            let data = new FormData();
            data.append('file', this.file);

            axios.post('http://127.0.0.1:8000/api/course/upload_cover/' + this.course.id,data,config)
                .then(function (response) {
                    this.$refs.clearForm.reset(),
                        this.coursePhoto = response.data
                    this.success = 'Cover Photo Changed'
                    this.editable = !this.editable
                    existingObj.success = response.data.success;
                })
                .catch(function (err) {
                    existingObj.output = err;
                });
        }


    },
    created(){
    this.getSubCategory()
    this.getSubject()
  },

}
</script>

<style>
.avatar-medium{
    height: 150px;
}
</style>
